<template>
  <div class="home login">
    <p class="hello" style="font-weight: 700">Admin Bereich</p>
    <v-divider class="my-5"></v-divider>
    <template v-if="!userIsAuthenticated">
      <p class="hello">Anmelden!</p>

      <v-layout v-if="error">
        <v-flex>
          <app-alert @dismissed="onDismissed" :text="this.error"></app-alert>
        </v-flex>
      </v-layout>
      <v-text-field
        v-model="userData.user"
        class="input-content"
        outlined
        dense
        rounded
        label="Benutzername"
      ></v-text-field>
      <v-text-field
        v-model="userData.password"
        :append-icon="value ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="() => (value = !value)"
        :type="value ? 'password' : 'text'"
        class="input-content"
        outlined
        @keyup.enter="onLogin()"
        dense
        rounded
        label="Passwort"
      ></v-text-field>
      <v-btn
        @click="onLogin()"
        class="anmelden-btn"
        rounded
        dense
        color="primary"
      >
        Anmelden!
      </v-btn>
    </template>
    <!-- *** -->

    <template v-if="userIsAuthenticated">
      <section v-if="allCodes !== undefined && allCodes && allCodes.length > 0">
        <p class="hello">Angemeldete Gäste</p>

        <p class="my-5 pb-5">
          <strong>Personen:</strong> {{ count.personen }} <br />
          <strong>Kinder ab 12:</strong> {{ count.kinder }}
        </p>
        <p class="my-5 pb-5">
          <strong>Insgesamt:</strong> {{ count.personen + count.kinder }}
        </p>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Suchen"
          single-line
          class="input-content"
          outlined
          dense
          rounded
        ></v-text-field>

        <v-data-table
          :headers="headers"
          :items="allCodesRueck"
          :single-expand="singleExpand"
          :search="search"
          :expanded="expanded"
          show-expand
          item-key="_code"
          class="mb-15"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              {{ item._info }}
            </td>
          </template>

          <template v-slot:item._used="{ item }">
            <v-chip :color="getColor(item._used)" dark>
              {{ item._used }}
            </v-chip>
          </template>

          <!-- <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="editItem(item), (editType = 'update')"
              v-if="item._email === ''"
            >
              mdi-check
            </v-icon>
          </template> -->
        </v-data-table>
      </section>

      <v-divider class="my-5"></v-divider>

      <p class="hello">Angelegte Gäste ohne Rückmeldung</p>

      <v-dialog v-model="dialogNewCode" max-width="500px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            rounded
            dense
            dark
            v-bind="attrs"
            v-on="on"
            class="my-5"
          >
            <v-icon left> mdi-plus </v-icon>
            Neuen Gast anlegen
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span>Neuen Gast anlegen</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              label="Name des Gastes"
              class="input-content"
              v-model="name"
              outlined
              dense
              rounded
              required
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              rounded
              dense
              dark
              class="mb-5"
              text
              @click="(dialogNewCode = false), (name = '')"
            >
              Abbrechen
            </v-btn>
            <v-btn
              color="primary"
              rounded
              :disabled="name === ''"
              dense
              class="mb-5"
              @click="
                (dialogNewCode = false), onNewCode(), changeView('shownewcode')
              "
            >
              <v-icon left> mdi-plus </v-icon>
              Anlegen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogNewCodeSuc" max-width="500px" persistent>
        <v-card class="pt-5 pb-4">
          <v-card-text v-if="userIsAuthenticated && view === 'shownewcode'">
            <v-spacer></v-spacer>
            <v-alert dense type="success"
              >Ein neuer Gast wurde erfolgreich angelegt!</v-alert
            >

            <p>
              <strong>{{ newCode._name }}</strong> <br />
              Code: {{ newCode._code }}
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              rounded
              dense
              dark
              @click="dialogNewCodeSuc = false"
            >
              Schließen
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-text-field
        v-model="searchNew"
        append-icon="mdi-magnify"
        label="Suchen"
        single-line
        class="input-content"
        outlined
        dense
        rounded
      ></v-text-field>

      <v-data-table
        :headers="headersNew"
        :items="allCodesNew"
        :items-per-page="5"
        :search="searchNew"
      >
        <template v-slot:top>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title
                >Möchtest du diesen Gast wirklich löschen?</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text rounded dense color="primary" @click="closeDelete"
                  >Abbrechen</v-btn
                >
                <v-btn rounded dense color="primary" @click="deleteItemConfirm">
                  <v-icon left> mdi-delete </v-icon>
                  Löschen</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog v-model="dialogEdit" max-width="500px">
            <v-card>
              <v-card-title>Diesen Gast zurückmelden?</v-card-title>
              <v-card-text>
                <v-form>
                  <v-select
                    :items="data.items"
                    v-model="editData.items"
                    required
                    label="Rückmeldung"
                    aria-placeholder="Rückmeldung"
                    class="input-content"
                    outlined
                    dense
                    rounded
                  ></v-select>
                  <v-row v-if="editData.items">
                    <v-col cols="12" md="6">
                      <v-select
                        :items="data.personen"
                        :rules="[(v) => !!v || '']"
                        required
                        v-model="editData.personen"
                        label="Anzahl Erwachsene"
                        class="input-content"
                        outlined
                        dense
                        rounded
                      ></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select
                        :items="data.kinder"
                        required
                        v-model="editData.kinder"
                        label="Anzahl Kinder unter 12*"
                        class="input-content"
                        outlined
                        dense
                        rounded
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text rounded dense color="primary" @click="closeEdit"
                  >Abbrechen</v-btn
                >
                <v-btn
                  type="submit"
                  :disabled="!checkFirstForm"
                  rounded
                  dense
                  color="primary"
                  @click="editItemConfirm"
                >
                  Speichern
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="editItem(item), (editType = 'set')"
          >
            mdi-check
          </v-icon>
          <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </template>
  </div>
</template>

<script>
export default {
  name: "Home",

  data() {
    return {
      search: "",
      searchNew: "",
      singleExpand: true,
      components: {},
      expanded: [],
      dialogDelete: false,
      editType: "",

      dialogEdit: false,

      dialogNewCode: false,
      dialogNewCodeSuc: false,

      editedIndex: -1,
      editedItem: {},

      data: {
        items: [
          {
            text: "Ich/Wir komme/n.",
            value: true,
          },
          {
            text: "Ich/Wir komme/n nicht",
            value: false,
          },
        ],
        personen: ["1", "2", "3", "4", "5"],
        kinder: ["0", "1", "2", "3", "4", "5"],
      },

      // count: {
      //   personen: 0,
      //   kinder: 0,
      // },

      userData: {
        user: "",
        password: "",
      },

      editData: {},

      name: "",
      value: true,
      view: "",
    };
  },

  computed: {
    allCodes() {
      const codes = this.$store.getters.getAllCodes;

      console.log("codes", codes);

      return codes;
    },

    allCodesRueck() {
      const codes = this.allCodes;

      let rueck = [];

      codes.forEach((element) => {
        if (element._used) {
          rueck.push(element);
        }
      });

      return rueck;
    },

    allCodesNew() {
      const codes = this.allCodes;

      let rueck = [];

      codes.forEach((element) => {
        if (!element._used) {
          rueck.push(element);
        }
      });

      return rueck;
    },

    error() {
      return this.$store.getters.getError;
    },
    user() {
      return this.$store.getters.getUser;
    },
    newCode() {
      console.log("newCode:", this.$store.getters.getNewCode);
      return this.$store.getters.getNewCode;
    },
    count() {
      var countPers = {
        personen: 0,
        kinder: 0,
      };

      this.allCodes.forEach((element) => {
        console.log(countPers.personen);
        if (!isNaN(parseInt(element._personen))) {
          countPers.personen += parseInt(element._personen);
        }
        if (!isNaN(parseInt(element._kinder))) {
          countPers.kinder += parseInt(element._kinder);
        }
      });
      return countPers;
    },

    userIsAuthenticated() {
      return (
        this.$store.getters.getUser !== null &&
        this.$store.getters.getUser !== undefined
      );
    },

    headers() {
      return [
        { text: "", value: "data-table-expand" },
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "_name",
          groupable: false,
        },
        // { text: "Rückgemeldet", value: "_used" },
        { text: "Zusage?", value: "_items" },
        { text: "Personen", value: "_personen", groupable: false },
        { text: "Kinder", value: "_kinder", groupable: false },
        { text: "Email", value: "_email", groupable: false },
        { text: "Code", value: "_code", groupable: false },

        // { text: "Nachricht", value: "_info" },
        {
          text: "Bearbeiten",
          value: "actions",
          sortable: false,
          align: "right",
        },
      ];
    },

    headersNew() {
      return [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "_name",
          groupable: false,
        },
        { text: "Code", value: "_code", groupable: false },
        {
          text: "Bearbeiten",
          value: "actions",
          sortable: false,
          align: "right",
        },
      ];
    },

    checkFirstForm() {
      if (
        (this.editData.items && this.editData.personen) ||
        this.editData.items === false
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    async onLogin() {
      await this.$store.dispatch("allCodes");
      this.$store.dispatch("setLogin", { userData: this.userData });
      console.log("error", this.$store.getters.getError);
    },

    onDismissed() {
      this.$store.dispatch("clearError");
    },
    changeView(view) {
      this.view = view;
    },
    async onNewCode() {
      await this.$store.dispatch("newCode", { name: this.name });
      this.dialogNewCodeSuc = true;
      this.name = "";
    },

    deleteItem(item) {
      this.editedIndex = this.allCodes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    editItem(item) {
      this.editedIndex = this.allCodes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogEdit = true;
      this.editData = {};
      console.log(this.editedItem);
      console.log(this.editedData);
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeEdit() {
      this.dialogEdit = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.editData = {};
      });
    },

    deleteItemConfirm() {
      console.log(this.editedItem);
      this.$store.dispatch("deleteCode", {
        code: this.editedItem._code,
        index: this.editedIndex,
      });
      this.closeDelete();
    },

    async editItemConfirm() {
      this.editData.code = this.editedItem._code;
      this.editData.email = "";
      this.editData.info = "";

      if (
        this.editData.items.toString() === this.data.items[0].value.toString()
      ) {
        this.editData.items = true;
        this.itemstex = this.data.items[0].text;
        console.log("sendDAZA after send", this.editData);
      } else {
        this.editData.items = false;
        this.itemstex = this.data.items[1].text;

        this.editData.personen = "0";
        this.editData.kinder = "0";
      }

      if (this.editData.info === null) {
        this.editData.info = "";
      }

      console.log("ITEM:", this.editedItem);
      console.log("DATA", this.editData);

      if (this.editType === "set") {
        await this.$store.dispatch("setRueckmeldungSelf", {
          sendData: this.editData,
          index: this.editedIndex,
        });
        console.log("SET", this.editType);
      } else {
        await this.$store.dispatch("updateRueckmeldung", {
          sendData: this.editData,
        });
        console.log("UPDATE", this.editType);
      }
      this.closeEdit();
      this.$store.dispatch("allCodes");
    },
  },

  async created() {},
};
</script>
